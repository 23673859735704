<template>

        <div v-if="localLabelJour != ''" class="ensembleAfficheurJour">
        {{ localLabelJour }}
        </div>

        <div v-if="listCreneauxJournee.length > 0" class="blocCreneaux">
            <div 
              class="creneau" 
              v-for="index in listCreneauxJournee.length" 
              :key="index"
            >
              <!--Bloc {{ index }}-->
              <AfficheurCreneau :localCreneau="listCreneauxJournee[index-1]" />
            </div>
        </div>



    
    
        

</template>


<script lang="ts">
import { defineComponent  } from 'vue';
import axios from 'axios';
import { ConstServeur, InfoServeur } from '../objetsTypeScript/InfoServeur';
import { useScreenOrientation  } from '../fonction/screenOrientation';
import { recupIdSemaineEnCours, recupDateVsIdSemaineEtIdJour, DateFiltre, datesEgales } from '../fonction/fonctionDate';
import { CreneauHoraire, organiserListCreneauOrdreChronologique, ajoutVideDansListeCreneauOrdreChronologique, recupHeureMin, recupHeureMax } from '../fonction/fonctionHoraire';
import { InteractionDiffuseurVideo } from '../objetsAafficher/InteractionDiffuseurVideo';
import AfficheurCreneau from './AfficheurCreneau.vue';
import { InteractionAfficheurImagePleinEcran } from '../objetsAafficher/InteractionAfficheurImagePleinEcran';
import { DataCommune } from '@/objetsTypeScript/InfoGeneral';

export default defineComponent ({
props:['pointerJour'],
components: {
    AfficheurCreneau
  },
  setup() {
    const { orientation, ratio } = useScreenOrientation();

    return { orientation, ratio };
  },
data() {
    return {
      localLabelJour: '' as string,
      listCreneauxJournee: [] as Array<any>,
      messageErreur: '' as any
    };
  },
  
methods:{
    
},
// lorsque l'afficheur datablock est cree
created() {
        //console.log("Creation datablock : "+this.localLabelJour.texte);

        //console.log("Affichage jour planning " + (this.pointerJour-1).toString());

        // date du jour planning
        let localDateDuJour : DateFiltre = recupDateVsIdSemaineEtIdJour(DataCommune.SemaineAffichee.id_semaine, this.pointerJour-1);
        // on modifie le texte affichee avec le texte de datablock
        this.localLabelJour = localDateDuJour.recupDateJour();
        
        //this.localLabelJour = "OK";

        // on recupere juste les creneaux de la journee //
        //////////////////////////////////////////////////
        let tempListCreneauxJournee : Array<any> = [];
        // pour tous les creneaux de la semaine
        for(let c = 0; c < DataCommune.SemaineAffichee.listeCreneaux.length; c++) {
            let tempDateCreneau = new DateFiltre(DataCommune.SemaineAffichee.listeCreneaux[c].date);
            // si la date du creneau est la date l'afficheur
            if(datesEgales(localDateDuJour, tempDateCreneau)) {
                // on met le creneau dans les creneaux de la journee
                tempListCreneauxJournee.push(DataCommune.SemaineAffichee.listeCreneaux[c]);
            }
        }
        //console.log("nb creneau sur journee : "+tempListCreneauxJournee.length.toString());

        // si il y a des creneau sur la journee
        if(tempListCreneauxJournee.length > 0){
          // on recupere les heures min et max de la semaine
          const heureMin = recupHeureMin(DataCommune.SemaineAffichee.listeCreneaux);
          const heureMax = recupHeureMax(DataCommune.SemaineAffichee.listeCreneaux);

          // on recupere la liste de creneau dans l'ordre chronologique //
          ////////////////////////////////////////////////////////////////
          const tempListCreneauxJourneeChronologique : Array<any> = organiserListCreneauOrdreChronologique(tempListCreneauxJournee);
          //console.log("nb creneau chronologique sur journee : "+tempListCreneauxJourneeChronologique.length.toString());

          // on ajoute des creneaux vide pour des espaces les creneaux //
          ///////////////////////////////////////////////////////////////

          this.listCreneauxJournee = ajoutVideDansListeCreneauOrdreChronologique(tempListCreneauxJourneeChronologique, heureMin, heureMax);
          //console.log("nb creneau avec vide sur journee : "+this.listCreneauxJournee.length.toString());


        }
       
    }

});


</script>




<style scoped>

/*************************
*** ENSEMBLE DATABLOCK ***
**************************/


.ensembleAfficheurJour {
  font-family: 'QuicksandBold';
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;          /* 5% de la hauteur de la page */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: clamp(5px, 1.7vw, 24px);
}

.blocCreneaux {
  font-family: 'QuicksandBold';
  position: relative;
  left: 0;
  width: 100%;
  height: 90%;
  padding: 3% 3%;
  box-sizing: border-box;
  
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-template-columns: 1fr;
  gap: 0.5%;             /* Espace de 5 px entre chaque élément */
  grid-auto-rows: 9.5%;
}

.creneau {
  background-color: #f0f0f0;
  /* Évite que le contenu agrandisse le bloc */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Centrage du contenu, facultatif */
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>