
import { Session } from '@/objetsTypeScript/Session';
import { InfoServeur, ConstServeur } from '../objetsTypeScript/InfoServeur';
import { DataCommune } from '@/objetsTypeScript/InfoGeneral';
import { reactive } from 'vue';
import { ResaCreneau, resaEgales } from './fonctionCreneau';
import { DateFiltre } from './fonctionDate';
import { creneauEgaux, CreneauHoraire } from './fonctionHoraire';
import { ouvrirPopup1Bouton, ouvrirPopup2Bouton  } from '../objetsAafficher/InteractionPopup';


// FAKE BDD permet de simuler la BDD lorsque utilise liver server dans visual studio code
const FAKE_BDD = reactive({
  TABLE_COMPTE_ETABLISSEMENT : [{"nom":"Les jardins Fruleux","id_etablissement":20888,"listeResidents":[{"nom":"Chloé","prenom":"Fruleux","listeFamille":[]},{"nom":"Thomas","prenom":"Narvaez","listeFamille":[]},{"nom":"Loïc","prenom":"Fruleux","listeFamille":[]},{"nom":"Baptiste","prenom":"Fruleux","listeFamille":[{"status":"Soeur","nomPrenom":"Marie Fruleux","email":"marie@wivy.fr","tel":"","telStandard":"","idFamille":"3440"},{"status":"Moi même","nomPrenom":"Baptiste Fruleux","email":"baptiste.fruleux@gmail.com","tel":"0605215137","telStandard":"+33605215137","idFamille":"7996"}]}],"enPause":false,"templatePlanningCree":true,"nbResaPossible":1,"emailUtilisateurWivy":"baptiste@wivy.fr","listSemainesActivees":[2878,2879,2880,2881,2882,2883]}] as any[],
  TABLE_PLANNING_SEMAINE : [{"id_etablissement":20888,"id_semaine":2878,"listeCreneaux":[{"date":{"jour":24,"mois":2,"annee":2025},"creneauH":{"heureDebut":{"heure":11,"minute":30,"seconde":0},"heureFin":{"heure":12,"minute":0,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":24,"mois":2,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":0,"seconde":0},"heureFin":{"heure":10,"minute":30,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":24,"mois":2,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":35,"seconde":0},"heureFin":{"heure":10,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""}],"status":1,"information":""}
  ,{"id_etablissement":20888,"id_semaine":2879,"listeCreneaux":[],"status":2,"information":"L'animateur est en vacances..."}
  ,{"id_etablissement":20888,"id_semaine":2880,"listeCreneaux":[{"date":{"jour":10,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":0,"seconde":0},"heureFin":{"heure":10,"minute":30,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":10,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":35,"seconde":0},"heureFin":{"heure":10,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":10,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":11,"minute":35,"seconde":0},"heureFin":{"heure":12,"minute":5,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":11,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":16,"minute":0,"seconde":0},"heureFin":{"heure":17,"minute":0,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":11,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":15,"minute":30,"seconde":0},"heureFin":{"heure":15,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":14,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":9,"minute":0,"seconde":0},"heureFin":{"heure":9,"minute":30,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":10,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":9,"minute":30,"seconde":0},"heureFin":{"heure":9,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":14,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":15,"minute":0,"seconde":0},"heureFin":{"heure":15,"minute":25,"seconde":0}},"nomFamille":"","nomResident":""}],"status":1,"information":""}
  ,{"id_etablissement":20888,"id_semaine":2881,"listeCreneaux":[{"date":{"jour":17,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":0,"seconde":0},"heureFin":{"heure":10,"minute":30,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":17,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":35,"seconde":0},"heureFin":{"heure":10,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":17,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":11,"minute":35,"seconde":0},"heureFin":{"heure":12,"minute":5,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":18,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":16,"minute":0,"seconde":0},"heureFin":{"heure":17,"minute":0,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":18,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":15,"minute":30,"seconde":0},"heureFin":{"heure":15,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":20,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":14,"minute":0,"seconde":0},"heureFin":{"heure":14,"minute":25,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":22,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":0,"seconde":0},"heureFin":{"heure":10,"minute":30,"seconde":0}},"nomFamille":"","nomResident":""}],"status":1,"information":""}
  ,{"id_etablissement":20888,"id_semaine":2882,"listeCreneaux":[{"date":{"jour":24,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":0,"seconde":0},"heureFin":{"heure":10,"minute":30,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":24,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":35,"seconde":0},"heureFin":{"heure":10,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":24,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":11,"minute":35,"seconde":0},"heureFin":{"heure":12,"minute":5,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":25,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":16,"minute":0,"seconde":0},"heureFin":{"heure":17,"minute":0,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":25,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":15,"minute":30,"seconde":0},"heureFin":{"heure":15,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":28,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":11,"minute":0,"seconde":0},"heureFin":{"heure":11,"minute":25,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":28,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":11,"minute":30,"seconde":0},"heureFin":{"heure":11,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""}],"status":1,"information":""}
  ,{"id_etablissement":20888,"id_semaine":2883,"listeCreneaux":[{"date":{"jour":31,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":0,"seconde":0},"heureFin":{"heure":10,"minute":30,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":31,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":35,"seconde":0},"heureFin":{"heure":10,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":1,"mois":4,"annee":2025},"creneauH":{"heureDebut":{"heure":16,"minute":0,"seconde":0},"heureFin":{"heure":17,"minute":0,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":1,"mois":4,"annee":2025},"creneauH":{"heureDebut":{"heure":15,"minute":30,"seconde":0},"heureFin":{"heure":15,"minute":55,"seconde":0}},"nomFamille":"","nomResident":""},{"date":{"jour":5,"mois":4,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":0,"seconde":0},"heureFin":{"heure":10,"minute":25,"seconde":0}},"nomFamille":"","nomResident":""}],"status":1,"information":""}
] as any[],
  TABLE_SESSION_EN_ATTENTE : [{"id_etablissement":20888,"date":{"jour":28,"mois":3,"annee":2025},"creneauH":{"heureDebut":{"heure":11,"minute":30},"heureFin":{"heure":11,"minute":55}},"mailUtilisateurWivy":"","emailFamille":"marie@wivy.fr","numTelFamille":""}
    ,{"id_etablissement":20888,"date":{"jour":5,"mois":4,"annee":2025},"creneauH":{"heureDebut":{"heure":10,"minute":0},"heureFin":{"heure":10,"minute":25}},"mailUtilisateurWivy":"","emailFamille":"baptiste@wivy.fr","numTelFamille":"+33605215137"}
  ] as any[]});

  // data BDD serveur wivy famille
  const NOM_SERVEUR = "wivyfaocontenus.mysql.db";
  const UTILISATEUR_BDD = "wivyfaocontenus";
  const MDP_BDD = "Bat230588911";
  const NOM_BDD = "wivyfaocontenus";


  // recupertion d'un objet dans la BDD avec son ID
  /*export async function enregistrementMajSession(inUtilisationSessionJSON : string) {
    // si on est sur live server de visual studio => on utilise une fausse BDD
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_IDE) {
      return -1;
    }
    // si on est sur wamp ou le serveur Wivy famille => on va chercher l'objet dans la base de donnee
    else {
    try {
      // envoi de la requete
      const response = await fetch('scriptPhp/remonterUtilisationSessionBDD.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'nomServeur': 'wivyfaocontenus.mysql.db', 
          'nomUtilisateur': 'wivyfaocontenus', 
          'motDePasse': 'Bat230588911', 
          'nomBdd': 'wivyfaocontenus', 
          'nomTable': ConstServeur.NOM_TABLE_SESSION,
          'OBJET': inUtilisationSessionJSON
        })
      });

      // on attent la reponse
      const data = await response.text();
      let idRecup = -1;
      // recuperation de l'ID session dans BDD
      try {
        console.log("Remontee session : "+data);
        idRecup = parseInt(data);
      } catch (error) {
        idRecup = -1;
      }
      // on retourne la data recupere
      return idRecup;
    } 
    // si erreur
    catch (error) {
      // on affiche erreur dans console
      console.error('Error:', error);
    }
  }

    // si on n'a reussi a trouve l'objet => on retourne rien
    return '';
  }*/





  // recupertion d'un objet dans la BDD avec son ID
  export async function lectureJsonVsIdEtablissement(inNomTable : string, inIdEtablissement : number) {
    // si on est sur live server de visual studio => on utilise une fausse BDD
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_FAKE) {
      let tableau_fake_json: any[] = [""];

      // on recupere la bonne table fake
      if(ConstServeur.NOM_TABLE_COMPTE_ETABLISSEMENT == inNomTable){
        tableau_fake_json = FAKE_BDD.TABLE_COMPTE_ETABLISSEMENT;
      }
      else if(ConstServeur.NOM_TABLE_PLANNING_SEMAINE == inNomTable){
        tableau_fake_json = FAKE_BDD.TABLE_PLANNING_SEMAINE;
      }

      // on recupere le json dans le tableau ou l'id etablissement est le meme que celui demande
      const jsonRecup = tableau_fake_json.find(item => item.id_etablissement == inIdEtablissement);

      // si le json existe
      if(jsonRecup){
        // on retourne le json
        return  JSON.stringify(jsonRecup);
      }
      // sinon
      else{
        // on retourne le vide
        return '';
      }
        
    }
    // si on est sur wamp ou le serveur Wivy famille => on va chercher l'objet dans la base de donnee
    else {
     
    try {
      // envoi de la requete
      const response = await fetch('visio/recupJsonGeneriqueVsIdEtablissement.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'nomServeur': NOM_SERVEUR, 
          'nomUtilisateur': UTILISATEUR_BDD, 
          'motDePasse': MDP_BDD, 
          'nomBdd': NOM_BDD, 
          'nomTable': inNomTable, 
          'ID_ETABLISSEMENT' : inIdEtablissement.toString()
        })
      });

      // on attent la reponse
      const data = await response.text();

      //console.log("Question recup : "+data);
      // on retourne la data recupere
      return data;
     
    } 
    // si erreur
    catch (error) {
      // on affiche erreur dans console
      console.error('Error:', error);
    }
  }

    // si on n'a reussi a trouve l'objet => on retourne rien
    return '';
  }

  // recupertion d'un objet dans la BDD avec son ID
  export async function lectureJsonVsIdEtablissementEtIdSemaine(inNomTable : string, inIdEtablissement : number, inIdSemaine : number) {
    // si on est sur live server de visual studio => on utilise une fausse BDD
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_FAKE) {
      let tableau_fake_json: any[] = [""];

      // on recupere la bonne table fake
      if(ConstServeur.NOM_TABLE_COMPTE_ETABLISSEMENT == inNomTable){
        tableau_fake_json = FAKE_BDD.TABLE_COMPTE_ETABLISSEMENT;
      }
      else if(ConstServeur.NOM_TABLE_PLANNING_SEMAINE == inNomTable){
        tableau_fake_json = FAKE_BDD.TABLE_PLANNING_SEMAINE;
      }
      else if(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE == inNomTable){
        tableau_fake_json = FAKE_BDD.TABLE_SESSION_EN_ATTENTE;
      }

      // on recupere le json dans le tableau ou l'id etablissement est le meme que celui demande
      const jsonRecup = tableau_fake_json.find(item => item.id_etablissement == inIdEtablissement && item.id_semaine == inIdSemaine);

      // si le json existe
      if(jsonRecup){
        // on retourne le json
        return  JSON.stringify(jsonRecup);
      }
      // sinon
      else{
        // on retourne le vide
        return '';
      }
        
    }
    // si on est sur wamp ou le serveur Wivy famille => on va chercher l'objet dans la base de donnee
    else {
     
    try {
      // envoi de la requete
      const response = await fetch('visio/recupJsonGeneriqueVsIdEtablissementEtIdSemaine.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'nomServeur': NOM_SERVEUR, 
          'nomUtilisateur': UTILISATEUR_BDD, 
          'motDePasse': MDP_BDD, 
          'nomBdd': NOM_BDD, 
          'nomTable': inNomTable, 
          'ID_ETABLISSEMENT' : inIdEtablissement.toString(), 
          'ID_SEMAINE' : inIdSemaine.toString()
        })
      });

      // on attent la reponse
      const data = await response.text();

      //console.log("Question recup : "+data);
      // on retourne la data recupere
      return data;
    } 
    // si erreur
    catch (error) {
      // on affiche erreur dans console
      console.error('Error:', error);
    }
  }

    // si on n'a reussi a trouve l'objet => on retourne rien
    return '';
  }

  // recupertion d'un objet dans la BDD avec son ID
  export async function majPlanningSemaineAffiche() {
    DataCommune.SemaineAffichee  = "";
    DataCommune.messageDansPlanning = "";
    DataCommune.listResaSemaineActuellementAffiche = [];

    // on recupere tout les resa sur la semaine
    lectureListeJsonVsIdEtablissement(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE, DataCommune.IdEtablissement).then(data => {
      // si on a recuperer les resa
      if(data != "") {
        console.log("Recup resa semaine : " + data);
        
        // on recupere le tableau de JSON
        const tableauJSON : any[] = JSON.parse(data);

        // on rempli la liste de resa avec les json recupere
        for(let j = 0; j < tableauJSON.length; j++){
          
          if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_FAKE) {
            DataCommune.listResaSemaineActuellementAffiche.push(new ResaCreneau(tableauJSON[j]));
          }
          else{
            DataCommune.listResaSemaineActuellementAffiche.push(new ResaCreneau(JSON.parse(tableauJSON[j])));
          }
          
          
        }

        // dans tous les cas on met a jour l'affichage de la semaine en cours
        // on charge la semaine en cours
        lectureJsonVsIdEtablissementEtIdSemaine(ConstServeur.NOM_TABLE_PLANNING_SEMAINE, DataCommune.IdEtablissement, DataCommune.IdSemaineAffichee).then(data => {
          // si on a reussi a charger la semaine en cours
          if(data != '') {
            console.log("Recup semaine : " + data);
            // on affiche l'etablissement a l'ecran pour demander la localisations
            DataCommune.SemaineAffichee = JSON.parse(data);

            // si la semaine est desactivee
            if(DataCommune.SemaineAffichee.status == 2) {
              DataCommune.messageDansPlanning = "Il n'y a pas de visio cette semaine : " + DataCommune.SemaineAffichee.information;
            }

            //this.messageErreur = this.localSemaineAffichee ;
          }
          // si on n'a pas reussi a recuperer l'etablissement
          else{
            // message erreur
            DataCommune.messageDansPlanning = "Impossible de charger la semaine...";
          }
        });

      }
      // si on n'a pas reussi a recuperer les resa
      else {
        // message erreur
        DataCommune.messageDansPlanning = "Impossible de charger les reservations sur la semaine...";
      }
    });

    // si on a une famille sur l'url
    if(DataCommune.Famille != ''){
      // on recupere toutes visio en cours de l'etablissement
      lectureListeJsonVsIdEtablissement(ConstServeur.NOM_TABLE_VISIO_EN_COURS, DataCommune.IdEtablissement).then(data => {
        // si on a recuperer les resa
        if(data != "") {
          console.log("Recup visio en cours : " + data);
          
          // on recupere le tableau de JSON
          const tableauJSON : any[] = JSON.parse(data);

          // pour chaque session visio en cours
          for(let j = 0; j < tableauJSON.length; j++){
            const tempSessionEnCours : any = JSON.parse(tableauJSON[j]);

            // si la session en cours est sur le meme mail ou telephone que la famille ici presente
            if( (tempSessionEnCours.emailFamille != "" &&  tempSessionEnCours.emailFamille == DataCommune.Famille.email)
            ||  (tempSessionEnCours.numTelFamille != "" &&  tempSessionEnCours.numTelFamille == DataCommune.Famille.telStandard)){
              
              // affichage du popup avec "Vous avez une visio en cours"
              ouvrirPopup1Bouton('Vous avez une visio en cours !', 'Rejoindre', () => { window.open(tempSessionEnCours.lienVisio, '_blank', 'noopener,noreferrer'); });
            }
          }
        }
      });
    }
    


        
  }


  // recupertion d'un objet dans la BDD avec son ID
  export async function lectureListeJsonVsIdEtablissement(inNomTable : string, inIdEtablissement : number) {
    // si on est sur live server de visual studio => on utilise une fausse BDD
    //console.log("Lecture table '"+inNomTable+"' / Etablissement " + inIdEtablissement.toString());
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_FAKE) {
      let tableau_fake_json: any[] = [""];

      // on recupere la bonne table fake
      if(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE == inNomTable){
        tableau_fake_json = FAKE_BDD.TABLE_SESSION_EN_ATTENTE;
        //console.log("Nb resa dans tableau : "+FAKE_BDD.TABLE_SESSION_EN_ATTENTE.length.toString());
      }

      //console.log("Test id_etablissement = " + inIdEtablissement.toString());
      // on recupere le json dans le tableau ou l'id etablissement est le meme que celui demande
      const listObjetRecup : any[] = tableau_fake_json.filter(item => item.id_etablissement == inIdEtablissement);

      // si le json existe
      if(listObjetRecup.length > 0){
        //console.log("Retour resa : " + JSON.stringify(listObjetRecup));
        // on retourne le json
        return JSON.stringify(listObjetRecup);
      }
      // sinon
      else{
        // on retourne un tableau vide
        return "[]";
      }
        
    }
    // si on est sur wamp ou le serveur Wivy famille => on va chercher l'objet dans la base de donnee
    else {
     
    try {
      // envoi de la requete
      const response = await fetch('/visio/recupListJSONVsIdEtablissement.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'nomServeur': NOM_SERVEUR, 
          'nomUtilisateur': UTILISATEUR_BDD, 
          'motDePasse': MDP_BDD, 
          'nomBdd': NOM_BDD, 
          'nomTable': inNomTable, 
          'ID_ETABLISSEMENT' : inIdEtablissement.toString()
        })
      });

      // on attent la reponse
      const data = await response.text();

      console.log("Recup list JSON : " + data);
      // on retourne la data recupere
      return data;
    } 
    // si erreur
    catch (error) {
      // on affiche erreur dans console
      console.error('Error:', error);
    }
  }

    // si on n'a reussi a trouve l'objet => on retourne rien
    return "";
  }

  // recupertion d'un objet dans la BDD avec son ID
  export async function ecritureJsonStandard(inNomTable : string, inJSON : any) {
    // si on est sur live server de visual studio => on utilise une fausse BDD
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_FAKE) {
      // on ajoute le JSON dans la bonne table
      if(ConstServeur.NOM_TABLE_COMPTE_ETABLISSEMENT == inNomTable){
        FAKE_BDD.TABLE_COMPTE_ETABLISSEMENT.push(inJSON);
      }
      else if(ConstServeur.NOM_TABLE_PLANNING_SEMAINE == inNomTable){
        FAKE_BDD.TABLE_PLANNING_SEMAINE.push(inJSON);
      }
      else if(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE == inNomTable){
        FAKE_BDD.TABLE_SESSION_EN_ATTENTE.push(inJSON);
      }

      // retourne ecriture JSON OK
      return true;
    }
    // si on est sur le serveur Wivy famille => on va chercher l'objet dans la base de donnee
    else {
     
    try {
      // envoi de la requete
      const response = await fetch('visio/ajoutModifJsonUniqueVsIdEtablissementEtIdSemaine.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'JSON': JSON.stringify(inJSON),
          'nomServeur': NOM_SERVEUR, 
          'nomUtilisateur': UTILISATEUR_BDD, 
          'motDePasse': MDP_BDD, 
          'nomBdd': NOM_BDD, 
          'nomTable': inNomTable, 
        })
      });

      // on attent la reponse
      const data : string = await response.text();

      // si on recoit "OK" en retour => c'est que l'ecriture c'est bien passee
      if(data == "OK") {
        return true;
      }
    } 
    // si erreur
    catch (error) {
      // on affiche erreur dans console
      console.error('Error:', error);
    }
  }

    // si on n'a pas reussi a trouve l'objet => on retourne faux
    return false;
  }

  // recupertion d'un objet dans la BDD avec son ID
  export async function ecritureJsonResaCreneau(inNomTable : string, inObjet : ResaCreneau) {
    console.log("Resa creneau : " + JSON.stringify(inObjet));

    // si on est sur live server de visual studio => on utilise une fausse BDD
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_FAKE) {
      // on ajoute le JSON dans la bonne table
      if(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE == inNomTable){
        FAKE_BDD.TABLE_SESSION_EN_ATTENTE.push(inObjet);
        console.log("Ajout resa dans tableau : " + JSON.stringify(inObjet));
      }

      // retourne ecriture JSON OK
      return true;
    }
    // si on est sur le serveur Wivy famille => on va chercher l'objet dans la base de donnee
    else {
     
    try {
      // envoi de la requete
      const response = await fetch('visio/ajoutJsonResaCreneau.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'JSON': JSON.stringify(inObjet),
          'dateEntier': new DateFiltre(inObjet.date).recupDateEntier().toString(),
          'heureEntier': new CreneauHoraire(inObjet.creneauH).heureDebut.recupHeureEntier().toString(),
          'nomServeur': NOM_SERVEUR, 
          'nomUtilisateur': UTILISATEUR_BDD, 
          'motDePasse': MDP_BDD, 
          'nomBdd': NOM_BDD, 
          'nomTable': inNomTable, 
        })
      });

      // on attent la reponse
      const data : string = await response.text();

      // si on recoit "OK" en retour => c'est que l'ecriture c'est bien passee
      if(data == "OK") {
        return true;
      }
    } 
    // si erreur
    catch (error) {
      // on affiche erreur dans console
      console.error('Error:', error);
    }
  }

    // si on n'a pas reussi a trouve l'objet => on retourne faux
    return false;
  }

  // recupertion d'un objet dans la BDD avec son ID
  export async function suppressionResaCreneau(inNomTable : string, inObjet : ResaCreneau) {
    console.log("Suppression Resa creneau : " + JSON.stringify(inObjet));

    // si on est sur live server de visual studio => on utilise une fausse BDD
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_FAKE) {
      // on ajoute le JSON dans la bonne table
      if(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE == inNomTable){
        // pour tous les elements du tableau
        for(let e = 0; e < FAKE_BDD.TABLE_SESSION_EN_ATTENTE.length; e++){
          // si on est sur la resa a supprimer
          if(resaEgales(new ResaCreneau(FAKE_BDD.TABLE_SESSION_EN_ATTENTE[e]), inObjet)) {
            // on supprime la resa
            FAKE_BDD.TABLE_SESSION_EN_ATTENTE.splice(e, 1);
            // on stop la boucle
            break;
          }
        }
      }

      // retourne ecriture JSON OK
      return true;
    }
    // si on est sur le serveur Wivy famille => on va chercher l'objet dans la base de donnee
    else {
     
    try {
      // envoi de la requete
      const response = await fetch('visio/supprimerJsonResaCreneau.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'id_etablissement': inObjet.id_etablissement.toString(),
          'dateEntier': new DateFiltre(inObjet.date).recupDateEntier().toString(),
          'heureEntier': new CreneauHoraire(inObjet.creneauH).heureDebut.recupHeureEntier().toString(),
          'nomServeur': NOM_SERVEUR, 
          'nomUtilisateur': UTILISATEUR_BDD, 
          'motDePasse': MDP_BDD, 
          'nomBdd': NOM_BDD, 
          'nomTable': inNomTable, 
        })
      });

      // on attent la reponse
      const data : string = await response.text();

      // si on recoit "OK" en retour => c'est que l'ecriture c'est bien passee
      if(data == "OK") {
        return true;
      }
    } 
    // si erreur
    catch (error) {
      // on affiche erreur dans console
      console.error('Error:', error);
    }
  }

    // si on n'a pas reussi a trouve l'objet => on retourne faux
    return false;
  }


  // recupertion d'un objet dans la BDD avec son ID
  export async function envoiEmail(inTitreMail : string, inNomExpediteur : string, inMailExpediteur : string, inNomReceveur : string, inMailReceveur: string, inMessageEmail :string) {
    console.log("envoi email de " + inMailExpediteur + " a "+ inMailReceveur);

    // si on est sur le serveur wivy => on envoi le mail
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_WIVYF) {
     
      try {
        // envoi de la requete
        const response = await fetch('visio/EnvoiMailAvecEntrees.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            'TITRE_MAIL': inTitreMail,
            'NOM_EXPEDITEUR_MAIL': inNomExpediteur,
            'MAIL_EXPEDITEUR': inMailExpediteur,
            'NOM_RECEVEUR_MAIL': inNomReceveur, 
            'MAIL_RECEVEUR': inMailReceveur, 
            'MESSAGE_MAIL': inMessageEmail, 
          })
        });

        // on attent la reponse
        const data : string = await response.text();

        console.log(data);

        // si on recoit "OK" en retour => c'est que le mail a bien ete envoye
        if(data == "OK") {
          return true;
        }
      } 
      // si erreur
      catch (error) {
        // on affiche erreur dans console
        console.error('Error:', error);
      }
    }

    // si on n'a pas reussi a envoyer email => on retourne faux
    return false;
  }

  // recupertion d'un objet dans la BDD avec son ID
  export async function envoiSMS(inNumero : string, inMessage : string) {
    console.log("envoi SMS a " + inNumero);

    // si on est sur le serveur wivy => on envoi le mail
    if(InfoServeur.serveurSelectionne == ConstServeur.SERVEUR_WIVYF) {
     
      try {
        // envoi de la requete
        const response = await fetch('visio/CallGatewayAPIsms.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            'numeroTelephone': inNumero,
            'message': inMessage,
          })
        });

        // on attent la reponse
        const data : string = await response.text();

        console.log(data);

        // si on recoit "OK" en retour => c'est que le mail a bien ete envoye
        if(data == "OK") {
          return true;
        }
      } 
      // si erreur
      catch (error) {
        // on affiche erreur dans console
        console.error('Error:', error);
      }
    }

    // si on n'a pas reussi a envoyer email => on retourne faux
    return false;
  }




