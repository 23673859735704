<template>

    <!-- Ensemble popup -->
    <div v-if="textePopup != ''" class="ensemblePopup">
        <!-- texte principal popup -->
        <div v-if="titrePopup == ''" class="affichageTextePopupPleinEcran">
            {{ textePopup }}
        </div>
        <div v-else class="affichagePopupAvecDeuxTexte">
            <div class="affichageTitrePopup">
                {{ titrePopup }}
            </div>
            <div class="affichageTextePopup">
                {{ textePopup }}
            </div>
        </div>
         <!-- bouton popup -->
        <div class="ensembleBouton">
            <div v-if="texteSurBouton1 != ''" class="boutonPopup" @click="clicBouton1()">
                {{ texteSurBouton1 }}
            </div>
            <div v-if="texteSurBouton2 != ''" class="boutonPopup" @click="clicBouton2()">
                {{ texteSurBouton2 }}
            </div>
        </div>
    </div>

</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { watch } from 'vue';
import { InteractionPopup } from '../objetsAafficher/InteractionPopup';


export default defineComponent ({
props:[],
data() {
    return {
      afficherPopupQuitter: false as boolean,
      titrePopup: '' as string,
      textePopup: '' as string,
      texteSurBouton1: '' as string, 
      texteSurBouton2: '' as string, 
      texteSurBouton3: '' as string
    }
  },
  
methods:{
    clicBouton1() {
        InteractionPopup.fonctionBouton1();
        InteractionPopup.textePopup = '';
    }, 
    clicBouton2() {
        InteractionPopup.fonctionBouton2();
        InteractionPopup.textePopup = '';
    }, 
    clicBouton3() {
        InteractionPopup.fonctionBouton3();
        InteractionPopup.textePopup = '';
    }, 
    clicFermerPopup() {
        this.textePopup = '';
        this.texteSurBouton1 = '';
        this.texteSurBouton2 = '';
        this.texteSurBouton3 = '';
    }, 
    // lorsque on lance le popup
    methodSurLancementPopup(inTextePopup : string){
        // titre popup
        this.titrePopup = InteractionPopup.titrePopup;
        // on affiche le texte du popup
        this.textePopup = inTextePopup;
        // on affiche les textes sur les boutons
        this.texteSurBouton1 = InteractionPopup.nomBouton1;
        this.texteSurBouton2 = InteractionPopup.nomBouton2;
        this.texteSurBouton3 = InteractionPopup.nomBouton3;
        //console.log('On lance le popup avec la valeur : '+inTextePopup);
    }
},
created() {
       //console.log("Affichage popup");
        
        
  watch(() => InteractionPopup.textePopup, (newValue, oldValue) => {
    // si on a modifie le texte popup
    if (newValue !== oldValue) {
        // si le texte popup est vide
        if(newValue == ""){
            console.log("clic fermer popup");
            // on ferme le popup
            this.clicFermerPopup();
        }
        // si le texte popup n'est pas vide
        else{
            // on change tous les textes sur le popup
            this.methodSurLancementPopup(newValue);
        }
    }
  });
    }

});

</script>




<style scoped>

/******************************
******** PARTIE POPUP *********
*******************************/

.ensemblePopup {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 100%;  /* 100% of the viewport height */
    padding: 5%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    background: #2F3E53;
    opacity: 0.98;
    border-radius: 0px;
}



/****************************
*** TEXTE PRINCIPAL POPUP ***
*****************************/

.affichagePopupAvecDeuxTexte {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 80%;  /* 100% of the viewport height */
    padding: 5%;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    /* fond 100% transparent */
    background-color: rgba(255, 255, 255, 0);
}

.affichageTitrePopup {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 30%;  /* 100% of the viewport height */
    padding: 1%;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    /* fond 100% transparent */
    background-color: rgba(255, 255, 255, 0);
   /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* taille texte 4% de la taille de l'ecran */
    font-size: calc(1vw + 1vh + 1vmin);
    /* texte blanc */
    color: white;
    border-radius: 0px;
}

.affichageTextePopup {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 70%;  /* 100% of the viewport height */
    padding: 1%;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    /* fond 100% transparent */
    background-color: rgba(255, 255, 255, 0);
   /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* taille texte 4% de la taille de l'ecran */
    font-size: calc(3vw + 3vh + 3vmin);
    /* texte blanc */
    color: white;
    border-radius: 0px;
}

.affichageTextePopupPleinEcran {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 80%;  /* 100% of the viewport height */
    padding: 5%;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    /* fond 100% transparent */
    background-color: rgba(255, 255, 255, 0);
   /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* taille texte 4% de la taille de l'ecran */
    font-size: calc(2vw + 2vh + 2vmin);
    /* texte blanc */
    color: white;
    border-radius: 0px;

}

/*******************
*** BOUTON POPUP ***
********************/

.ensembleBouton{
    box-sizing: border-box;
    width: 100%;  /* 100% of the viewport width */
    height: 8%;  /* 50% of the viewport height */
    padding: 0;
    position: relative;
    margin-top: 0;
    background:rgba(255, 255, 255, 0);
    border-radius: 0px;

    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(0.7vw + 0.7vh + 0.7vmin);

}

.boutonPopup{
    width: 30%;  /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : left; 
    top: 0;
    left: 0;
    margin-left: 15%;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: black;
    /* fond bleu */
    background-color: white; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px; /* Top-left, top-right, bottom-right, bottom-left */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>