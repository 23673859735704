export const ConstServeur = {
  SERVEUR_FAKE : 0,
  SERVEUR_WAMP : 1,
  SERVEUR_WIVYF : 2,

  // URL SERVEUR
  URL_SERVEUR : ["http://localhost:8080/", "http://localhost/", "https://wivy-famille.fr/"] as string[],

  // REPERTOIRE PIECE JOINTE

  //IMAGE directement sur question
  REPERTOIRE_IMAGE_QUESTION : ['images/', "launcher/ImageQuestion/", "launcher/ImageQuestion/"] as string[],
  // SON directement sur question
  REPERTOIRE_SON_QUESTION : ['sons/', "launcher/SonsQuestion/", "launcher/SonsQuestion/"] as string[],


  //IMAGE dans bibliotheque
  REPERTOIRE_IMAGE_BIBLI : ['images/', "launcher/ImageBibliotheque/", "launcher/ImageBibliotheque/"] as string[],
  //SONS dans bibliotheque
  REPERTOIRE_SON_BIBLI : ['sons/', "launcher/SonsBibliotheque/", "launcher/SonsBibliotheque/"] as string[],
  // VIDEO dans bibliotheque
  REPERTOIRE_VIDEO_BIBLI : ['videos/', "launcher/VideosBibliotheque/", "launcher/VideosBibliotheque/"] as string[],

  // TABLE BDD
  NOM_TABLE_COMPTE_ETABLISSEMENT : 'compte_visio_etablissement', 
  NOM_TABLE_PLANNING_SEMAINE : 'planning_semaine_visio',
  NOM_TABLE_VISIO_EN_ATTENTE : 'resa_creneau', 
  NOM_TABLE_VISIO_EN_COURS : 'visio_en_cours'
  };

  // eslint-disable-next-line
  export let InfoServeur = {
    serveurSelectionne : ConstServeur.SERVEUR_WIVYF,
  
    // URL SERVEUR
    //urlServeur : 'http://192.168.1.31:8080/',
    urlServeur : ConstServeur.URL_SERVEUR[ConstServeur.SERVEUR_WIVYF] as string,

    // REPERTOIRE PIECE JOINTE

  //IMAGE directement sur question
    repertoireImage :  ConstServeur.REPERTOIRE_IMAGE_QUESTION[ConstServeur.SERVEUR_WIVYF] as string,
    // SON directement sur question
    repertoireSon : ConstServeur.REPERTOIRE_SON_QUESTION[ConstServeur.SERVEUR_WIVYF] as string,
  
    //IMAGE dans bibliotheque
    repertoireImageBibli : ConstServeur.REPERTOIRE_IMAGE_BIBLI[ConstServeur.SERVEUR_WIVYF] as string,
    //SONS dans bibliotheque
    repertoireSonBibli : ConstServeur.REPERTOIRE_SON_BIBLI[ConstServeur.SERVEUR_WIVYF] as string,
    // VIDEO dans bibliotheque
    repertoireVideoBibli : ConstServeur.REPERTOIRE_VIDEO_BIBLI[ConstServeur.SERVEUR_WIVYF] as string

  };

  export function initServeur(inIndexServeur : number){
    InfoServeur.serveurSelectionne = inIndexServeur;

// URL SERVEUR
    //urlServeur : 'http://192.168.1.31:8080/',
    InfoServeur.urlServeur = ConstServeur.URL_SERVEUR[inIndexServeur] as string,

    // REPERTOIRE PIECE JOINTE

    //IMAGE directement sur question
    InfoServeur.repertoireImage =  ConstServeur.REPERTOIRE_IMAGE_QUESTION[inIndexServeur] as string,
    // SON directement sur question
    InfoServeur.repertoireSon = ConstServeur.REPERTOIRE_SON_QUESTION[inIndexServeur] as string,
  
    //IMAGE dans bibliotheque
    InfoServeur.repertoireImageBibli = ConstServeur.REPERTOIRE_IMAGE_BIBLI[inIndexServeur] as string,
    //SONS dans bibliotheque
    InfoServeur.repertoireSonBibli = ConstServeur.REPERTOIRE_SON_BIBLI[inIndexServeur] as string,
    // VIDEO dans bibliotheque
    InfoServeur.repertoireVideoBibli = ConstServeur.REPERTOIRE_VIDEO_BIBLI[inIndexServeur] as string

  }