import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "conteneurBouton" }
const _hoisted_3 = {
  key: 0,
  class: "labelCompteEtablissement"
}
const _hoisted_4 = { class: "conteneurBouton" }
const _hoisted_5 = {
  key: 0,
  class: "blocPlanning"
}
const _hoisted_6 = {
  key: 1,
  class: "blocMessageDansPlanning"
}
const _hoisted_7 = {
  key: 2,
  class: "ensembleMessageErreur"
}
const _hoisted_8 = { class: "texteMessageErreur" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AfficheurJourPlanning = _resolveComponent("AfficheurJourPlanning")!
  const _component_AfficheurPopup = _resolveComponent("AfficheurPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "boutonSemaine",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicVoirSemainePrecedante && _ctx.clicVoirSemainePrecedante(...args)))
        }, "Semaine précédante")
      ]),
      (_ctx.nomEtablissementAffiche)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.nomEtablissementAffiche), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "boutonSemaine",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clicVoirSemaineSuivante && _ctx.clicVoirSemaineSuivante(...args)))
        }, "Semaine suivante")
      ])
    ]),
    (_ctx.DataCommune.SemaineAffichee != '' && _ctx.messageDansPlanning == '' && _ctx.DataCommune.listResaSemaineActuellementAffiche != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, (index) => {
            return _createElementVNode("div", {
              class: "jourPlanning",
              key: index
            }, [
              _createVNode(_component_AfficheurJourPlanning, { pointerJour: index }, null, 8, ["pointerJour"])
            ])
          }), 64))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.messageDansPlanning), 1)),
    _createVNode(_component_AfficheurPopup),
    (_ctx.messageErreur)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.messageErreur), 1),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clicRechargerLaPage())),
            class: "boutonRechargerLaPage"
          }, "Recharger la page")
        ]))
      : _createCommentVNode("", true)
  ], 64))
}