<template>
    <!-- affichage des boutons permettant de lancer different type de question
    <div class="boutonAfficherQuestion" @click="clicQuestionId(7449)">
         100% texte
    </div>  

    <div class="boutonAfficherQuestion" @click="clicQuestionId(25784)">
        Image bibliotheque
    </div>  

    <div class="boutonAfficherQuestion" @click="clicQuestionId(25826 )">
        Image et son bibliotheque
    </div>  

    <div class="boutonAfficherQuestion" @click="clicQuestionId(6197)">
        Image et son sur datablock
    </div> 

    <div class="boutonAfficherQuestion" @click="clicQuestionId(26249)">
        100% Vidéo
    </div>  -->

    <div class="header">
      <div class="conteneurBouton">
        <!-- Bouton avec une classe et un écouteur d'événement click -->
        <button class="boutonSemaine" @click="clicVoirSemainePrecedante">Semaine précédante</button>
      </div>

    <div v-if="nomEtablissementAffiche" class="labelCompteEtablissement">
        {{ nomEtablissementAffiche }} 
      </div>

      <div class="conteneurBouton">
      <!-- Bouton avec une classe et un écouteur d'événement click -->
      <button class="boutonSemaine" @click="clicVoirSemaineSuivante">Semaine suivante</button>
    </div>
</div>

    <!-- Bloc principal avec 7 éléments -->
    <div v-if="DataCommune.SemaineAffichee != '' && messageDansPlanning == '' && DataCommune.listResaSemaineActuellementAffiche != null" class="blocPlanning">
      <div 
        class="jourPlanning" 
        v-for="index in 7" 
        :key="index"
      >
        <!--Bloc {{ index }} -->
        <AfficheurJourPlanning :pointerJour="index" />
      </div>
    </div>
    <div v-else class="blocMessageDansPlanning">
      {{ messageDansPlanning }}
    </div>

  <!-- afficheur question QCM-->
    <!-- <AffichageQuestionQCM v-if="questionAafficher != ''" @clicQuitterAffichageQuestion="clicFermerQuestion" @clicPasserQuestionSuivante="chargementQuestionSuivante" :objetQuestion="questionAafficher" />
-->
  <!-- diffuseur son commun -->
   <!--  <DiffuseurSon/>-->

  <!-- afficheur karaoke  -->
    <!-- <AfficheurKaraoke/>-->

  <!-- diffuseur video commun -->
    <!-- <DiffuseurVideo/>-->

  <!-- afficheur image en plein ecran -->
   <!--<AfficheurImagePleinEcran/> -->

  <!-- POPUP -->
   <AfficheurPopup/>



  <!-- si on a une erreur => on l'affiche en plein ecran avec bouton recharger la page -->
   <div v-if="messageErreur" class="ensembleMessageErreur">
    <div class="texteMessageErreur">
      {{ messageErreur }} 
    </div>

    <button @click="clicRechargerLaPage()" class="boutonRechargerLaPage">Recharger la page</button>
  </div>

</template>

<script lang="ts">
import { defineComponent   } from 'vue';
import AffichageQuestionQCM from './components/AffichageQuestionQCM.vue'
import AfficheurJourPlanning from './objetsAafficher/AfficheurJourPlanning.vue'
import { lectureJsonVsIdEtablissement, majPlanningSemaineAffiche, lectureListeJsonVsIdEtablissement } from './fonction/fonctionServeur';
import { recupIdSemaineEnCours, recupDateVsIdSemaineEtIdJour } from './fonction/fonctionDate';
import { ConstServeur, initServeur, InfoServeur } from './objetsTypeScript/InfoServeur';
import { DataCommune } from '@/objetsTypeScript/InfoGeneral';
import DiffuseurSon from './objetsAafficher/DiffuseurSon.vue'; 
import AfficheurKaraoke from './objetsAafficher/AfficheurKaraoke.vue'; 
import DiffuseurVideo from './objetsAafficher/DiffuseurVideo.vue'; 
import AfficheurImagePleinEcran from './objetsAafficher/AfficheurImagePleinEcran.vue'; 
import AfficheurPopup from './objetsAafficher/AfficheurPopup.vue'; 
import { Session, initSession} from './objetsTypeScript/Session';

const LIMIT_DISTANCE_DEVICE_ETABLISSEMENT = 1000; // limit de distance entre le device et l'etablissement pour lancer le mode famille
const typeJeuxChoisi = 1;

// calcul de la distance (metres) entre 2 positions (latitude, longitude)
function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c * 1000; // Convert to meters
  return distance;
}

  function deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

export default defineComponent ({
props:[],
components: {
  AfficheurJourPlanning, 
  AfficheurPopup
},
data() {
    return {
      chargementEtablissementEnCours : true,
      accesClient : false,
      DataCommune,
      messageErreur: '' as string, 
      messageDansPlanning: '' as string, 
      nomEtablissementAffiche: '', 
      chargementQuestionSuivanteEnCours : false,
      latitude: 0,
      longitude: 0, 
      sessionActuelle : null as Session | null
    }
  },
  
methods:{
    afficherSemaineVsIdSemaine() {
      // on reset la semaine actuelle affichee
      DataCommune.SemaineAffichee = '';
      this.messageDansPlanning = "";
      // on recupere les dates de debut et fin de la semaine avec l'id de la semaine
      let texteDatesSemaine = " entre " + recupDateVsIdSemaineEtIdJour(DataCommune.IdSemaineAffichee, 0).recupDateTexte() + " et " + recupDateVsIdSemaineEtIdJour(DataCommune.IdSemaineAffichee, 6).recupDateTexte();
    
      // AFFICHAGE HEADER
      // si l'id famill est 1 => animateur
      if(DataCommune.IdFamille == 1) {
        // on affiche nom etablissement / nom famille / date debut et fin semaine
        this.nomEtablissementAffiche = DataCommune.CompteEtablissement.nom + " / ANIMATION / " +  texteDatesSemaine;
      }
      // si on a reussi a recuperer la famille => on affiche le nom de la famille
      else if(DataCommune.Famille != ''){
        // on affiche nom etablissement / nom famille / date debut et fin semaine
        this.nomEtablissementAffiche = DataCommune.CompteEtablissement.nom + " / " + DataCommune.Famille.nomPrenom + " pour " + DataCommune.Resident.prenom + " " + DataCommune.Resident.nom + " / " +  texteDatesSemaine;
      }
      else{
        DataCommune.Famille = ''
        // on affiche nom etablissement / nom famille / date debut et fin semaine
        this.nomEtablissementAffiche = DataCommune.CompteEtablissement.nom + " / FAMILLE INCONNUE / " +  texteDatesSemaine;
      }

      // si la semaine en cours a ete activee par l'etablissement
      if(DataCommune.CompteEtablissement.listSemainesActivees.includes(DataCommune.IdSemaineAffichee)) {
        // on charge le planning semaine
        majPlanningSemaineAffiche();
      }
      else{
        // message erreur
        this.messageDansPlanning = "Cette semaine n'a pas été activée par la résidence...";

      }
},
    clicVoirSemaineSuivante() {
      console.log("clic semaine suivante !");
      // on pointe sur la semaine suivante
      DataCommune.IdSemaineAffichee = DataCommune.IdSemaineAffichee + 1;
      // on affiche la semaine
      this.afficherSemaineVsIdSemaine();
    },
    clicVoirSemainePrecedante() {
      console.log("clic semaine precedante !");
      // on pointe sur la semaine precedante
      DataCommune.IdSemaineAffichee = DataCommune.IdSemaineAffichee - 1;
      // on affiche la semaine
      this.afficherSemaineVsIdSemaine();
    },
    clicRechargerLaPage() {
        window.location.reload();
    }
},
// sur la creation de la page
created() {
  this.chargementEtablissementEnCours = true;
  // on initialise le serveur avec les bons liens
  initServeur(ConstServeur.SERVEUR_WIVYF);    
  
  // on check l'etablissement a partir de l'url
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idEtablissementSurUrl = urlParams.get('e'); //?e=20888
  const idFamilleSurUrl = urlParams.get('f'); //?f=XXX

  if(idEtablissementSurUrl != null && idFamilleSurUrl != null)
  {
    DataCommune.IdEtablissement = parseInt(idEtablissementSurUrl, 10); // Convert string to integer
    DataCommune.IdFamille = parseInt(idFamilleSurUrl, 10); // Convert string to integer

    // si l'id de l'etablissement est null
    if (isNaN(DataCommune.IdEtablissement)) {
      this.messageErreur = "Impossible de recuperer l'id de l'établissement. Assurez vous d'avoir le bon lien.";
    }
    // si l'id de la famille est null
    else if(isNaN(DataCommune.IdFamille)){
      this.messageErreur = "Impossible de recuperer l'id de votre famille. Assurez vous d'avoir le bon lien.";
    }
    else{
      
      // on va chercher le compte visio de l'etablissement
      lectureJsonVsIdEtablissement(ConstServeur.NOM_TABLE_COMPTE_ETABLISSEMENT, DataCommune.IdEtablissement).then(data => {
        // si on a reussi a recuperer le compte etablissement
        if(data != '') {
          // on recupere l'objet compte eztablissement
          DataCommune.CompteEtablissement = JSON.parse(data);
          // on recupere l'id de la semaine en cours
          DataCommune.IdSemaineAffichee = recupIdSemaineEnCours();
          

          // recuperation de la famille dans le compte etablissement
          DataCommune.Famille = '';
          DataCommune.Resident = '';
          if(DataCommune.IdFamille != 1){
            for(let r = 0; r < DataCommune.CompteEtablissement.listeResidents.length; r++){
              //console.log("Resident : "+ JSON.stringify(this.localCompteEtablissement.listeResidents[r]));
              for(let f = 0; f < DataCommune.CompteEtablissement.listeResidents[r].listeFamille.length; f++){
                //console.log("Famille : "+JSON.stringify(this.localCompteEtablissement.listeResidents[r].listeFamille[f]));
                if(DataCommune.CompteEtablissement.listeResidents[r].listeFamille[f].idFamille == DataCommune.IdFamille.toString()){
                  DataCommune.Resident = DataCommune.CompteEtablissement.listeResidents[r];
                  DataCommune.Famille = DataCommune.CompteEtablissement.listeResidents[r].listeFamille[f];
                }
              }
            }
          }

          // si on n'a pas reussi a recuperer la famille
          if(DataCommune.Famille == ''){
            this.messageErreur = "Impossible de recuperer la famille. Assurez vous d'avoir le bon lien.";
          }
          // si on a reussi a recuperer la famille
          else{
            // on affiche la semaine en cours avec l'id etablissement
            this.afficherSemaineVsIdSemaine();
          }
          
        }
        // si on n'a pas reussi a recuperer l'etablissement
        else{
          // message erreur
          this.messageErreur = "Impossible de charger l'établissement "+DataCommune.IdEtablissement.toString()+". Essayez de recharger la page.";
        }
      });
    }
  }
  else {
    this.messageErreur = "Impossible de recuperer l'id de l'établissement et/ou de la famille. Assurez vous d'avoir le bon lien.";
  }

  this.chargementEtablissementEnCours = false;

}

});

</script>

<style>
@font-face {
  font-family: 'QuicksandBold';
  src: url('../public/fonts/Quicksand_Bold.otf') format('opentype');
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Conteneur principal qui occupe la page entière */
.app-container {
  position: relative;
  width: 100%;
  height: 100vh; /* 100% de la hauteur visible */
  overflow: hidden; /* Empêche le dépassement */
}

.header {
  font-family: 'QuicksandBold';
    /* Occupe 100% de la largeur et 5% de la hauteur de l'écran */
    margin-left: 0%;
    width: 100%;
    height: 10%; /* ou 5% si tu préfères, mais 5vh est souvent plus fiable */
    
    /* Afin de placer correctement nos éléments sur la même ligne */
    display: flex;
    align-items: center;
    
    /* Positionne la barre en haut */
    position: fixed;
    top: 0;
    left: 0;

    /* Optionnel : un fond pour bien voir la zone du header */
    background-color: white;
  }

  .conteneurBouton {
    font-family: 'QuicksandBold';
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center; /* Centre horizontalement le bouton dans l'espace attribué */
    align-items: center;     /* Centre verticalement le bouton */
  }
  .boutonSemaine {
    font-family: 'QuicksandBold';
    width: 100%;
    height: 100%;
    font-size: clamp(5px, 1.5vw, 15px);
  }

  .labelCompteEtablissement {
    width: 80%;
    text-align: center; /* Centre horizontalement le texte */
    font-size: clamp(5px, 2vw, 24px);
  }

.blocPlanning {
  font-family: 'QuicksandBold';
  position: absolute;
  top: 10%;             /* Démarre juste après le header */
  left: 0;
  width: 100%;
  height: 90%;         /* 90% de la hauteur de la page */
  
  /* On inclut les “marges” latérales dans cette zone,
     grâce à un padding horizontal */
  padding: 3% 3%;
  box-sizing: border-box;
  
  /* Disposition en grille sur 7 colonnes */
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5vw;             /* Espace de 5 px entre chaque élément */
}

.blocMessageDansPlanning {
  font-family: 'QuicksandBold';
  position: absolute;
  top: 10%;             /* Démarre juste après le header */
  left: 0;
  width: 100%;
  height: 90%;         /* 90% de la hauteur de la page */
  
  /* On inclut les “marges” latérales dans cette zone,
     grâce à un padding horizontal */
  padding: 3% 3%;
  box-sizing: border-box;

  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;
}

.jourPlanning {
  background-color: #f0f0f0; /* Exemple de couleur de fond */
  height: 100%;
}

.boutonAfficherQuestion{
  box-sizing: border-box;
    width: 10em;  /* 100% of the viewport width */
    height: 6em;  /* 50% of the viewport height */
    overflow: auto; 
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : left; 
    bottom: 0;
    left: 0;
    margin: 2%;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #2F3E53; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px; /* Top-left, top-right, bottom-right, bottom-left */
    /* attention fit par rapport a texte ici*/
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

.textePleinEcran {
  box-sizing: border-box;
  width: 100%;  /* 100% of the viewport width */
  height: 100%;  /* 50% of the viewport height */
  padding: 10%;
  position: fixed;
  /* met le bloc a la suite du bloc precedant */
  float : left; 
  bottom: 0;
  left: 0;
  margin: 0;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* texte blanc */
  color: #2F3E53;
  /* fond bleu */
  background-color: white; /* Color of the rectangle */
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;
}



.ensembleAfficheurEtablissement {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 5%;
  position: fixed;
  /* met le bloc a la suite du bloc precedant */
  float : left; 
  bottom: 0;
  left: 0;
  margin: 0;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* texte blanc */
  color: #2F3E53;
  /* fond bleu */
  background-color: whitesmoke; /* Color of the rectangle */
}

.nomDeLetablissement { 
  box-sizing: border-box;
  width: 100%;
  height: 30%;
  padding: 5%;
  position: relative;
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: calc(2vw + 2vh + 2vmin);
}

.partieEtablissement {
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  padding: 5%;
  position: relative;
}

.partieTexteClient {
  box-sizing: border-box;
  width: 100%;
  height: 50%;
  padding: 5%;
  position: relative;
}

.boutonCommencer{
  box-sizing: border-box;
    width: 50%;  /* 100% of the viewport width */
    height: 25%;  /* 50% of the viewport height */
    min-width: 250px; 
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    bottom: 0;
    left: 0;
    margin: 0 auto;
    margin-top: 5%;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #2F3E53; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

/*************************************
****** AFFICHAGE MESSAGE ERREUR ******
**************************************/
.ensembleMessageErreur {
box-sizing: border-box;
  width: 100%;  /* 100% of the viewport width */
  height: 100%;  /* 50% of the viewport height */
  padding: 10%;
  position: fixed;
  /* met le bloc a la suite du bloc precedant */
  float : left; 
  bottom: 0;
  left: 0;
  margin: 0;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* fond bleu */
  background-color: #2F3E53; /* Color of the rectangle */
}

.texteMessageErreur {
  box-sizing: border-box;
  width: 100%;  /* 100% of the viewport width */
  height: 70%;  /* 50% of the viewport height */
  padding: 10%;
  position: relative;
  /* met le bloc a la suite du bloc precedant */
  float : left; 
  /* texte blanc */
  color: white;
  /* fond bleu */
  background-color: #2F3E53; /* Color of the rectangle */
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;
}

.boutonRechargerLaPage {
  box-sizing: border-box;
  width: 50%;  /* 100% of the viewport width */
  height: 30%;  /* 50% of the viewport height */
  min-width: 250px; 
  padding: 0;
  /* met le bloc a la suite du bloc precedant */
  bottom: 0;
  left: 0;
  margin: 0 auto;
  margin-top: 5%;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* texte blanc */
  color: #2F3E53;
  /* fond bleu */
  background-color: white; /* Color of the rectangle */
  /* bord arrondi */
  border-radius: 25px;
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;

}



</style>
