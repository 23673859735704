<template>

    <div v-if="localHoraire != '' && localStatus == 'Disponible'" class="fondCreneau" @click="clicReserverCreneau">
        <div class="affichageHoraire">
      {{ localHoraire }}
      </div>

      <div class="statusCreneau">
        {{ localStatus }}
      </div>
    </div>

    <div v-if="localHoraire != '' && localStatus == 'Mon créneau'" class="fondMonCreneau" @click="clicSupprimerCreneau">
        <div class="affichageHoraire">
      {{ localHoraire }}
      </div>

      <div class="statusCreneau">
        {{ localStatus }}
      </div>
    </div>

    <div v-if="localHoraire != '' && localStatus != 'Disponible' && localStatus != 'Mon créneau' " class="fondCreneauGrise">
        <div class="affichageHoraire">
      {{ localHoraire }}
      </div>

      <div class="statusCreneau">
        {{ localStatus }}
      </div>
    </div>

</template>


<script lang="ts">
import { defineComponent  } from 'vue';
import axios from 'axios';
import { useScreenOrientation  } from '../fonction/screenOrientation';
import { DateFiltre, date1avantDate2, datesEgales, recupDateAjourdhui } from '../fonction/fonctionDate';
import { CreneauHoraire, HeureFiltre, creneauEgaux, recupHeurePasse } from '../fonction/fonctionHoraire';
import { ResaCreneau } from "../fonction/fonctionCreneau";
import { ecritureJsonResaCreneau, lectureListeJsonVsIdEtablissement, majPlanningSemaineAffiche, suppressionResaCreneau, envoiEmail, envoiSMS } from '../fonction/fonctionServeur';
import { ouvrirPopup1Bouton, ouvrirPopup2Bouton  } from '../objetsAafficher/InteractionPopup';
import { ConstServeur } from '@/objetsTypeScript/InfoServeur';
import { DataCommune } from '@/objetsTypeScript/InfoGeneral';

export default defineComponent ({
props:['localCreneau'],
components: {
  
},
setup() {
const { orientation, ratio } = useScreenOrientation();

return { orientation, ratio };
},
data() {
return {
  localHoraire: '' as string,
  localStatus: '' as string,
  messageErreur: '' as any
};
},

methods:{
  clicReserverCreneau(){

    ouvrirPopup2Bouton('Etes vous certain de vouloir reserver ce créneau ?', 'Réserver', this.clicReserver, 'Annuler', this.clicAnnuler);
  }, 
  clicSupprimerCreneau(){

    ouvrirPopup2Bouton('Etes vous certain de vouloir supprimer votre réservation ?', 'Supprimer', this.clicSupprimer, 'Annuler', this.clicAnnuler);
  },
  clicReserver(){
    //console.log("clic Reserver");

    if(DataCommune.Famille == '') {
      ouvrirPopup1Bouton('Impossible d\'effectuer une reservation car vous n\'avez pas de compte', 'OK', this.clicAnnuler);

    }
    // si on a une famille
    else{
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // on verifie d'abord que le creneau requis n'a pas ete reserver ET que la famille n'a pas deja reserve un creneau sur la semaine //
      // on charge tous les creneaux reserve pour l'etablissement sur l'id semaine pointe
      lectureListeJsonVsIdEtablissement(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE, DataCommune.SemaineAffichee.id_etablissement).then(data => {
        // si on a reussi a charger la semaine en cours
        if(data != "") {
          //console.log("Recup list json : " + data);
          // on recupere la liste de resa dans un tableau d'objet
          const listeCreneauxSemaineActualise: ResaCreneau[] = [];
          // on recupere le tableau de JSON
          const tableauJSON : any[] = JSON.parse(data);

          // on rempli la liste de resa avec les json recupere
          for(let j = 0; j < tableauJSON.length; j++){
            listeCreneauxSemaineActualise.push(new ResaCreneau(JSON.parse(tableauJSON[j])));
          } 
          
          let nbCreneauDejaReserveParFamille = 0;
          let creneauDejaSelectionne = false;

          // pour tous les creneaux de la semaine actuelle
          for(let c = 0; c < listeCreneauxSemaineActualise.length; c++) {
            // si le creneau est deja utilise par la famille (meme mail ou meme telephone)
            if(listeCreneauxSemaineActualise[c].emailFamille == DataCommune.Famille.email || listeCreneauxSemaineActualise[c].numTelFamille == DataCommune.Famille.telStandard) {
              // on enregistre  que l'etablissement a deja un creneau sur la semaine
              nbCreneauDejaReserveParFamille++;
            }

            //console.log(JSON.stringify(listeCreneauxSemaineActualise[c].creneauH));
            //console.log(JSON.stringify(new CreneauHoraire(this.localCreneau.creneauH)));
            // si on a trouve un creneau
            if(datesEgales(listeCreneauxSemaineActualise[c].date, new DateFiltre(this.localCreneau.date)) && creneauEgaux(listeCreneauxSemaineActualise[c].creneauH, new CreneauHoraire(this.localCreneau.creneauH))) {
              // on enregistre que le creneau est deja selectionne
              creneauDejaSelectionne = true;
            }
          }

          // si la famille a deja reserve un creneau sur la semaine
          if(nbCreneauDejaReserveParFamille >= DataCommune.CompteEtablissement.nbResaPossible) {
            if(DataCommune.CompteEtablissement.nbResaPossible == 1)
              ouvrirPopup1Bouton('Vous avez déjà réservé un créneau. Vous ne pouvez avoir qu\'une réservation à la fois.', 'OK', this.clicAnnuler);
            else 
              ouvrirPopup1Bouton('Vous avez déjà ' + DataCommune.CompteEtablissement.nbResaPossible.toString() + ' réservation en cours. C\'est le maximum autorisé.', 'OK', this.clicAnnuler);
              
            // maj planning semaine affiche
            majPlanningSemaineAffiche();
          }
          // si le creneau est deja reserve
          else if(creneauDejaSelectionne) {
            ouvrirPopup1Bouton('Le créneau que vous voulez réserver n\'est plus disponible.', 'OK', this.clicAnnuler);
            // maj planning semaine affiche
            majPlanningSemaineAffiche();
          }
          // si le creneau est disponible
          else {
            //console.log("Creation creneau pour id etablissement : " + DataCommune.IdEtablissement.toString());
            // creation de l'objet visio en attente ( = objet reservation)
            const localVisioEnAttente : ResaCreneau = new ResaCreneau(DataCommune.IdEtablissement, new DateFiltre(this.localCreneau.date), new CreneauHoraire(this.localCreneau.creneauH), DataCommune.CompteEtablissement.emailUtilisateurWivy, DataCommune.Famille.email, DataCommune.Famille.telStandard);

            // on enregistre la reservation dans la visio en attente
            ecritureJsonResaCreneau(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE, localVisioEnAttente).then(enregistrementOK => {
              // si on a reussi a enregistrer la reservation du creneau
              if(enregistrementOK) {
                //console.log("Enregistrement visio en attente OK");

                ouvrirPopup1Bouton('Votre réservation a bien été prise en compte !', 'OK', this.clicAnnuler);

                // si on a le mail de la famille => on la notif par mail
                if(localVisioEnAttente.mailUtilisateurWivy != ""){
                  // on envoi l'email de confirmation pour le creneau a la famille
                  let corpsEmailFamille : string = "Bonjour, \n\nVotre rendez-vous visio avec " + DataCommune.Resident.prenom + " " + DataCommune.Resident.nom;
                  corpsEmailFamille += " est confirmé le " +localVisioEnAttente.date.recupDateTexte() + " entre " + localVisioEnAttente.creneauH.heureDebut.recupHeureTexte() + " et " + localVisioEnAttente.creneauH.heureFin.recupHeureTexte() + ".";
                  corpsEmailFamille += "\n\nVous recevrez un email avec le lien de la visio 10 minutes avant le début de la visio.";
                  corpsEmailFamille += " Si vous avez un empechement, vous pouvez annuler votre rendez-vous en cliquant sur votre créneau sur le lien suivant : https://wivy-famille.fr/?e="+DataCommune.CompteEtablissement.id_etablissement.toString()+"&f="+DataCommune.Famille.idFamille.toString();
                  corpsEmailFamille += "\n\nBien cordialement,";
                  corpsEmailFamille += "\n\n" + DataCommune.CompteEtablissement.nom;
                  envoiEmail("Confirmation de votre visio", DataCommune.CompteEtablissement.nom, localVisioEnAttente.mailUtilisateurWivy, localVisioEnAttente.emailFamille, localVisioEnAttente.emailFamille, corpsEmailFamille);
                }

                // si on a le mail de l'utilisateur wivy => on le notif par mail
                if(localVisioEnAttente.mailUtilisateurWivy != ""){
                  // on envoi l'email de confirmation pour le creneau a l'animateur
                  let corpsEmailAnim : string = "Bonjour, \n\n" + DataCommune.Famille.nomPrenom + " a réservé un rendez-vous visio avec " + DataCommune.Resident.prenom + " " + DataCommune.Resident.nom + ".";
                  corpsEmailAnim += "\nCette visio est prévue le " +localVisioEnAttente.date.recupDateTexte() + " entre " + localVisioEnAttente.creneauH.heureDebut.recupHeureTexte() + " et " + localVisioEnAttente.creneauH.heureFin.recupHeureTexte() + ".";
                  corpsEmailAnim += "\n\nVous recevrez un email avec le lien de la visio 10 minutes avant le début de la visio. Ou vous pourrez rejoindre la visio directement dans la partie visio de l'application Wivy.";
                  corpsEmailAnim += "\n\nBien cordialement,";
                  corpsEmailAnim += "\n\nBaptiste de Wivy";
                  envoiEmail("Confirmation visio avec une famille", "Baptiste de Wivy", "baptiste@wivy.fr", DataCommune.CompteEtablissement.nom, localVisioEnAttente.mailUtilisateurWivy, corpsEmailAnim);
                }
                
                // si on a le numero de telephone de la famille => on la notif par SMS
                if(localVisioEnAttente.numTelFamille != "") {
                  let corpSMSFamille : string = "Bonjour, \n\nVotre rendez-vous visio avec " + DataCommune.Resident.prenom + " " + DataCommune.Resident.nom;
                  corpSMSFamille += " est confirmé le " +localVisioEnAttente.date.recupDateTexte() + " entre " + localVisioEnAttente.creneauH.heureDebut.recupHeureTexte() + " et " + localVisioEnAttente.creneauH.heureFin.recupHeureTexte() + ".";
                  corpSMSFamille += "\n\nVous recevrez un email avec le lien de la visio 10 minutes avant le début de la visio.";
                  corpSMSFamille += "\n\nSi vous avez un empêchement, vous pouvez annuler votre rendez-vous en cliquant sur votre créneau sur le lien suivant : https://wivy-famille.fr/?e="+DataCommune.CompteEtablissement.id_etablissement.toString()+"&f="+DataCommune.Famille.idFamille.toString();
                  corpSMSFamille += "\n\nBien cordialement,";
                  corpSMSFamille += "\n\n" + DataCommune.CompteEtablissement.nom;
                  envoiSMS(localVisioEnAttente.numTelFamille, corpSMSFamille);
                }
                
              }
              // si on n'a pas reussi a enregistrer la reservation du creneau
              else{
                //console.log("Probleme enregsitrement visio en attente");

                ouvrirPopup1Bouton('Echec de la réservation de votre créneau', 'OK', this.clicAnnuler);
              }

              // maj planning semaine affiche
              majPlanningSemaineAffiche();
            });
          }

          
        }
        // si on n'a pas reussi a recuperer l'etablissement
        else {
          //console.log("Impossible de recuperer semaine actualisee");

          ouvrirPopup1Bouton('Echec de la réservation de votre créneau', 'OK', this.clicAnnuler);
          // maj planning semaine affiche
          majPlanningSemaineAffiche();
        }
      });

    }
    
    
    
  },
  clicSupprimer(){
    //console.log("clic Reserver");

    // on cree la reservation creneau qu'on va devoir supprimer
    const localVisioEnAttente : ResaCreneau = new ResaCreneau(DataCommune.IdEtablissement, this.localCreneau.date, this.localCreneau.creneauH, DataCommune.CompteEtablissement.emailUtilisateurWivy, DataCommune.Famille.email, DataCommune.Famille.telStandard);

    // on envoi a suppression de la reservation
    suppressionResaCreneau(ConstServeur.NOM_TABLE_VISIO_EN_ATTENTE, localVisioEnAttente).then(enregistrementOK => {
      // si on a reussi a enregistrer la reservation du creneau
      if(enregistrementOK) {
        //console.log("Enregistrement visio en attente OK");

        ouvrirPopup1Bouton('Votre réservation a bien été supprimée !', 'OK', this.clicAnnuler);

        // on envoi l'email de confirmation pour le creneau a la famille
        let corpsEmailAnim : string = "Bonjour, \n\n" + DataCommune.Famille.nomPrenom + " vient d'annuler son rendez-vous visio avec " + DataCommune.Resident.prenom + " " + DataCommune.Resident.nom;
        corpsEmailAnim += " prévue le " + new DateFiltre(localVisioEnAttente.date).recupDateTexte() + " entre " + new HeureFiltre(localVisioEnAttente.creneauH.heureDebut).recupHeureTexte() + " et " + new HeureFiltre(localVisioEnAttente.creneauH.heureFin).recupHeureTexte() + ".";
        corpsEmailAnim += "\n\nBien cordialement,";
        corpsEmailAnim += "\n\nBaptiste de Wivy";
        envoiEmail("Annulation visio avec une famille", "Baptiste de Wivy", "baptiste@wivy.fr", DataCommune.CompteEtablissement.nom, localVisioEnAttente.mailUtilisateurWivy, corpsEmailAnim);
      }
      // si on n'a pas reussi a enregistrer la reservation du creneau
      else{
        //console.log("Probleme enregsitrement visio en attente");

        ouvrirPopup1Bouton('Echec de la suppression de votre réservation', 'OK', this.clicAnnuler);
      }

      // maj planning semaine affiche
      majPlanningSemaineAffiche();
    });
       
    
    
  },
  clicAnnuler(){
    //console.log("clic Annuler");
  }
},
// lorsque l'afficheur datablock est cree
created() {
    

   //let tempJsonCreneau = this.localCreneau.creneauH;
    //let tempDateCreneau = new DateFiltre(tempJsonCreneau.jour, tempJsonCreneau.mois, tempJsonCreneau.annee);
//this.localHoraire = this.localCreneau.creneauH.heureDebut.heure.toString() + "h"+this.localCreneau.creneauH.heureDebut.minute.toString() + " - " +this.localCreneau.creneauH.heureFin.heure.toString() + "h"+this.localCreneau.creneauH.heureFin.minute.toString();
    
  // si le creneau n'est pas vide
  if(this.localCreneau != ""){
    //console.log("Affichage creneau : "+JSON.stringify(this.localCreneau));

    // recuperation objet creneau horaire
    let creneauHoraire = new CreneauHoraire(this.localCreneau.creneauH);

    // affichage creneau horaire
    this.localHoraire = creneauHoraire.recupHoraireTexte();

    
    // par defaut on met creneau disponible
    this.localStatus = "Disponible";

    // si la date est passe => on met date passe
    if( date1avantDate2(new DateFiltre(this.localCreneau.date), recupDateAjourdhui()) 
    ||  (datesEgales(new DateFiltre(this.localCreneau.date), recupDateAjourdhui()) && recupHeurePasse(this.localCreneau.creneauH.heureFin))) {
      this.localStatus = "Passé";
    }
    // si la date n'est pas passe
    else {
      // si on est sur la date d'aujourd hui => on met que le creneau n'est pas reservable
      if(datesEgales(new DateFiltre(this.localCreneau.date), recupDateAjourdhui())){
        this.localStatus = "Non réservable";
      }

      //("Nb resa semaine = " +  DataCommune.listResaSemaineActuellementAffiche.length.toString());
      // pour toutes les reservations de la semaine
      for(let r = 0; r < DataCommune.listResaSemaineActuellementAffiche.length; r++) {
        const localResa : ResaCreneau = DataCommune.listResaSemaineActuellementAffiche[r];
        //console.log(JSON.stringify(localResa));
        //console.log("compare date : " + localResa.date.recupDateTexte() + " / " + new DateFiltre(this.localCreneau.date).recupDateTexte());
        //console.log("compare horaire : " + localResa.creneauH.recupHoraireTexte() + " / " + creneauHoraire.recupHoraireTexte());
        // si la resa est sur la meme heure et meme date que le creneau affiche
        if(datesEgales(DataCommune.listResaSemaineActuellementAffiche[r].date, new DateFiltre(this.localCreneau.date)) 
        && creneauEgaux(DataCommune.listResaSemaineActuellementAffiche[r].creneauH, creneauHoraire)) {
          //console.log("Comparaison resa : " + JSON.stringify(DataCommune.listResaSemaineActuellementAffiche[r]));
          //console.log("avec : " + JSON.stringify(DataCommune.Famille));
          // si c'est la famille qui a reserve ce creneau
          if(localResa.familleDejaDessus(DataCommune.Famille)) {
            this.localStatus = "Mon créneau";
          }
          // si la resa est celle d'une autre famille => on met indisponible
          else {
            this.localStatus = "Indisponible";
          }

          break;
        }

      }
    }
    
  }

    
}

});


</script>




<style scoped>

/*************************
*** ENSEMBLE DATABLOCK ***
**************************/

.fondCreneau {
      font-family: 'QuicksandBold';
      font-size: 14px;
      background-color: #2196F3;  /* Couleur de fond bleu */
      width: 100%;
      height: 100%;         /* 90% de la hauteur de la page */
      color: #ffffff;            /* Couleur du texte en blanc */
      border-radius: 8px;        /* Bords arrondis */
      padding: 2%;             /* Marge intérieure */
      display: inline-block;     /* Garde la forme du contenu */
    }

    .fondCreneauGrise {
      font-family: 'QuicksandBold';
      font-size: 14px;
      background-color: gray;  /* Couleur de fond bleu */
      width: 100%;
      height: 100%;         /* 90% de la hauteur de la page */
      color: #ffffff;            /* Couleur du texte en blanc */
      border-radius: 8px;        /* Bords arrondis */
      padding: 2%;             /* Marge intérieure */
      display: inline-block;     /* Garde la forme du contenu */
    }

    .fondMonCreneau {
      font-family: 'QuicksandBold';
      font-size: 14px;
      background-color: orangered;  /* Couleur de fond bleu */
      width: 100%;
      height: 100%;         /* 90% de la hauteur de la page */
      color: #ffffff;            /* Couleur du texte en blanc */
      border-radius: 8px;        /* Bords arrondis */
      padding: 2%;             /* Marge intérieure */
      display: inline-block;     /* Garde la forme du contenu */
    }


.affichageHoraire {
  position: relative;
  left: 0;
  width: 100%;
  height: 50%;         /* 90% de la hauteur de la page */
  /* On inclut les “marges” latérales dans cette zone,
  grâce à un padding horizontal */
  padding: 3% 3%;
  box-sizing: border-box;
  text-align: center;
  font-size: clamp(5px, 1.7vh, 24px);
}

.statusCreneau {
  position: relative;
  left: 0;
  width: 100%;
  height: 50%;         /* 90% de la hauteur de la page */
  /* On inclut les “marges” latérales dans cette zone,
  grâce à un padding horizontal */
  padding: 3% 3%;
  box-sizing: border-box;
  text-align: center;
  font-size: clamp(5px, 1.5vh, 24px);
}

</style>