export class DateFiltre {
  public jour: number;
  public mois: number;
  public annee: number;

  // 1. On déclare les signatures "surchargées" (facultatives, mais recommandées pour la clarté) :
  constructor();
  constructor(jour: number, mois: number, annee: number);
  constructor(objet: any);

  // 2. On n’a qu’un seul constructeur qui implémente la logique.
  constructor(param1?: number | any, param2?: number, param3?: number) {
    if (typeof param1 === 'object') {
      // Signature "constructor(objet: any)"
      this.jour = param1.jour ?? 99;
      this.mois = param1.mois ?? 99;
      this.annee = param1.annee ?? 9999;
    } else {
      // Signature "constructor(jour: number, mois: number, annee: number)" ou sans paramètre
      this.jour = param1 ?? 99;
      this.mois = param2 ?? 99;
      this.annee = param3 ?? 9999;
    }
  }

  // recuper la date en texte au format JJ/MM/AAAA
  public recupDateTexte(): string {
    const padZero = (val: number) => (val < 10 ? `0${val}` : `${val}`);
    const jj = padZero(this.jour);
    const mm = padZero(this.mois);
    const aaaa = this.annee;

    return `${jj}/${mm}/${aaaa}`;
  }

    public recupDateEntier(): number {

      return this.annee + this.mois * 10000 + this.jour * 1000000;
    }

  public recupDateJour(): string {
    // Liste des jours de la semaine en français
    const joursSemaine = [
      'dimanche',
      'lundi',
      'mardi',
      'mercredi',
      'jeudi',
      'vendredi',
      'samedi'
    ];

    // On reconstruit la date pour en extraire le jour de la semaine
    const dateObj = new Date(this.annee, this.mois - 1, this.jour);
    // getDay() renvoie un entier de 0 (dimanche) à 6 (samedi)
    const jourSemaine = joursSemaine[dateObj.getDay()];

    // On renvoie la chaîne de caractères souhaitée, ex : "mardi 7"
    return `${jourSemaine} ${this.jour}`;
  }
}

// fonction permettant de recuperer la semaine actuelle
// id semaine permet de recuperer la bonne semaine dans la BDD
export function recupIdSemaineEnCours(): number {
  // 1. Obtenir la date du jour
  const today = new Date();

  // 2. Trouver le jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
  const dayOfWeek = today.getDay();
  // Décaler de façon à ce que lundi = 0, mardi = 1, ..., dimanche = 6
  const offset = (dayOfWeek + 6) % 7;

  // 3. Trouver le lundi de la semaine
  const mondayOfWeek = new Date(today.getTime() - offset * 24 * 60 * 60 * 1000);

  // 4. Définir une date de référence (un lundi)
  const referenceMonday = new Date(1970, 0, 5); // 5 janvier 1970 (lundi)

  // 5. Calculer la différence en jours
  const oneDayMs = 24 * 60 * 60 * 1000;
  const totalDays = Math.floor(
    (mondayOfWeek.getTime() - referenceMonday.getTime()) / oneDayMs
  );

  // 6. Diviser par 7 pour obtenir l’ID de la semaine (+1 pour le décalage voulu)
  const weekId = Math.floor(totalDays / 7) + 1;

  return weekId;
}

export function recupDateAjourdhui() : DateFiltre {
  // 1. Obtenir la date du jour
  const today = new Date();

  return new DateFiltre(today.getDate(), today.getMonth()+1, today.getFullYear());
}

export function date1avantDate2(inDate1 : DateFiltre, inDate2 : DateFiltre) : boolean {
  // si date 1 sur annee precedante => date 1 avant date 2
  if(inDate1.annee < inDate2.annee){
    return true;
  }
  else if(inDate1.annee == inDate2.annee){
    // si les 2 dates sont sur la meme annee ET date 1 sur mois precendant => date 1 avant date 2
    if(inDate1.mois < inDate2.mois){
      return true;
    }
    else if(inDate1.mois == inDate2.mois){
      // si les 2 dates sont sur la meme annee et meme mois ET date 1 sur jour precendant => date 1 avant date 2
      if(inDate1.jour < inDate2.jour) {
        return true;
      }
    }
  }

  // sinon date 1 n'est pas avant date 2
  return false;
}

export function datePassee(inDate : DateFiltre) : boolean {
  //console.log("Compare date : " + inDate.recupDateTexte() + " / "+recupDateAjourdhui().recupDateTexte());
  // si la date en entree est al date d'ajourd'hui ou avant la date d'aujourdhui
  return date1avantDate2(inDate, recupDateAjourdhui()) || datesEgales(inDate, recupDateAjourdhui());
}

// on recupere un date JJ/MM/AAAA a partir de l'id semaine et pointer jour
// cette fonction permet de recuperer le lundi et dimanche d'une semaine en particuliere
// pour savoir entre quelle date et quelle date on doit afficher le planning
export function recupDateVsIdSemaineEtIdJour(
  inIdSemaine: number,
  inPointerJour: number
): DateFiltre {
  // 1. Date de référence (un lundi)
  const referenceMonday = new Date(1970, 0, 5); // 5 janvier 1970

  // 2. Calcul de l’offset en jours
  const dayOffset = (inIdSemaine - 1) * 7 + inPointerJour;

  // 3. Détermine la date résultante
  const resultDate = new Date(
    referenceMonday.getTime() + dayOffset * 24 * 60 * 60 * 1000
  );

  // 4. Extraire jour, mois, année
  const jour = resultDate.getDate();
  const mois = resultDate.getMonth() + 1; // +1 pour obtenir [1..12]
  const annee = resultDate.getFullYear();

  // 5. Renvoyer un objet DateFiltre
  return new DateFiltre(jour, mois, annee);
}

// on verifie si 2 dates sont egales
export function datesEgales(a: DateFiltre, b: DateFiltre): boolean {
  return (
    a.jour == b.jour &&
    a.mois == b.mois &&
    a.annee == b.annee
  );
}