export class HeureFiltre {
    public heure: number;
    public minute: number;

    constructor();
    constructor(heure: number, minute: number);
    constructor(objet: any);

  // 2. On n’a qu’un seul constructeur qui implémente la logique.
  constructor(param1?: number | any, param2?: number) {
    if (typeof param1 === 'object') {
      // Signature "constructor(objet: any)"
      this.heure = param1.heure ?? 0;
      this.minute = param1.minute ?? 0;
    } else {
      // Signature "constructor(jour: number, mois: number, annee: number)" ou sans paramètre
      this.heure = param1 ?? 0;
      this.minute = param2 ?? 0;
    }
  }
  
    // recuper la date en texte au format JJ/MM/AAAA
    public recupHeureTexte(): string {
      const padZero = (val: number) => (val < 10 ? `0${val}` : `${val}`);
      const hh = this.heure;
      const mm = padZero(this.minute);
  
      return `${hh}h${mm}`;
    }

    // recuper la date en texte au format JJ/MM/AAAA
    public recupHeureEntier(): number {
      return this.heure*100 + this.minute;
    }

    
  }

  export class CreneauHoraire {
    public heureDebut: HeureFiltre;
    public heureFin: HeureFiltre;
  

    constructor();
    constructor(heureDebut: HeureFiltre, heureFin: HeureFiltre);
    constructor(objet: any);

  // 2. On n’a qu’un seul constructeur qui implémente la logique.
  constructor(param1?: HeureFiltre | any, param2?: HeureFiltre) {
    if (typeof param1 === 'object') {
      // Signature "constructor(objet: any)"
      this.heureDebut = new HeureFiltre(param1.heureDebut ?? (new HeureFiltre(0,0))) ;
      this.heureFin = new HeureFiltre(param1.heureFin ?? (new HeureFiltre(0,0)));
    } else {
      // Signature "constructor(jour: number, mois: number, annee: number)" ou sans paramètre
      this.heureDebut = param1 ?? new HeureFiltre(0,0);
      this.heureFin = param2 ?? new HeureFiltre(0,0);
    }
  }
  
    // recuper la date en texte au format JJ/MM/AAAA
    public recupHoraireTexte(): string {
      return this.heureDebut.recupHeureTexte() + "-" + this.heureFin.recupHeureTexte();
    }
  }

// calcul si un heure est avant ou  apres un autre
export function heure1avantLe2(inHeure1: HeureFiltre, inHeure2: HeureFiltre): boolean {
  
  if(inHeure1.heure < inHeure2.heure){
    return true;
  }
  else if(inHeure1.heure == inHeure2.heure){
    if(inHeure1.minute < inHeure2.minute){
      return true;
    }
    else{
      return false;
    }

  }
  else{
    return false;
  }
  
}

// on demande si l'heure est passe
export function recupHeurePasse(inHeure :HeureFiltre): boolean {
  // recup heure maintenant
  const heureMaintenant : HeureFiltre = recupHeureMaintenant();
  // si l'heure de maintenant est apres l'heure en entree
  if(heureMaintenant.heure > inHeure.heure || (heureMaintenant.heure == inHeure.heure && heureMaintenant.minute > inHeure.minute)) {
    return true;
  }
  else {
    return false;
  }
}

function recupHeureMaintenant(): HeureFiltre {
  const maintenant = new Date();
  const heures = maintenant.getHours();
  const minutes = maintenant.getMinutes();

  return new HeureFiltre(heures, minutes);
}

// verfie si 2 heures egales
export function heuresEgales(inHeure1: HeureFiltre, inHeure2: HeureFiltre): boolean {
  
  return inHeure1.heure == inHeure2.heure && inHeure1.minute == inHeure2.minute;
}

// verfie si 2 creneaux egaux
export function creneauEgaux(inCreneau1: CreneauHoraire, inCreneau2: CreneauHoraire): boolean {
  
  return heuresEgales(inCreneau1.heureDebut, inCreneau2.heureDebut) && heuresEgales(inCreneau1.heureFin, inCreneau2.heureFin);
}

// calcul si creneau horaire avant ou  apres un autre
export function creneauHoraire1avantLe2(inCreneau1: CreneauHoraire, inCreneau2: CreneauHoraire): boolean {
  return heure1avantLe2(inCreneau1.heureDebut, inCreneau2.heureDebut);
}

export function organiserListCreneauOrdreChronologique(listObjet: any[]): any[] {
  for (let i = 0; i < listObjet.length - 1; i++) {
    for (let j = 0; j < listObjet.length - i - 1; j++) {
      const creneauObjet1 = new CreneauHoraire(listObjet[j].creneauH);
      const creneauObjet2 = new CreneauHoraire(listObjet[j+1].creneauH);
      if (!creneauHoraire1avantLe2(creneauObjet1, creneauObjet2)) {
        // Échange des valeurs si la précédente est plus grande que la suivante
        const temp = listObjet[j];
        listObjet[j] = listObjet[j + 1];
        listObjet[j + 1] = temp;
      }
    }
  }
  return listObjet;
}

export function recupHeureMin(listCreneau: any[]): HeureFiltre {
  let localHeureMin : HeureFiltre = new HeureFiltre(10, 0);
  for (let c = 0; c < listCreneau.length; c++) {
      const tempHeureDebut = new HeureFiltre(listCreneau[c].creneauH.heureDebut);

      if(heure1avantLe2(tempHeureDebut, localHeureMin)) {
        localHeureMin = tempHeureDebut;
      }
  }
  // on retourne l'heure min
  return localHeureMin;
}
  export function recupHeureMax(listCreneau: any[]): HeureFiltre {
    let localHeureMax : HeureFiltre = new HeureFiltre(16, 0);
    for (let c = 0; c < listCreneau.length; c++) {
        const tempHeureFin = new HeureFiltre(listCreneau[c].creneauH.heureFin);

  
        if(heure1avantLe2(localHeureMax, tempHeureFin)){
          localHeureMax = tempHeureFin;
        }
    }


    // on retourne l'heure max
    return localHeureMax;
  }

// calcul si creneau horaire avant ou  apres un autre
function recupNbMinuteCreneau(inCreneauHoraire: CreneauHoraire): number {
  return recupNbMinuteEntreHeures(inCreneauHoraire.heureDebut, inCreneauHoraire.heureFin);
}

// calcul si creneau horaire avant ou  apres un autre
function recupNbMinuteEntreHeures(inHeureAvant: HeureFiltre, inHeureApres: HeureFiltre): number {
  return (inHeureApres.heure - inHeureAvant.heure) * 60 + (inHeureApres.minute - inHeureAvant.minute);
}


function tailleCreneauMoyen(listObjetCreneau: any[]): number {
  // somme de taille de creneau en minute
  let sommeTailleCreneau = 0;
  for (let c = 0; c < listObjetCreneau.length; c++) {
    sommeTailleCreneau += recupNbMinuteCreneau(new CreneauHoraire(listObjetCreneau[c].creneauH));
  }

  return sommeTailleCreneau / listObjetCreneau.length;
}

export function ajoutVideDansListeCreneauOrdreChronologique(listObjetCreneau: any[], heureMin:HeureFiltre, heureMax:HeureFiltre): any[] {
  const returnTableauCreneau : any[] = [];
  const nbCreneauMaxAvecVide = 10;
  const nbMinutesRangeComplete : number = recupNbMinuteEntreHeures(heureMin, heureMax);
  const tailleMoyenCreneaux = nbMinutesRangeComplete / nbCreneauMaxAvecVide;

  // si il y a deja 10 creneaux sur la journee => on renvoi la liste tel quel
  if(listObjetCreneau.length >= nbCreneauMaxAvecVide){
    return listObjetCreneau;
  }
  // sinon on continue

  const nbCreneauVideAajouter = nbCreneauMaxAvecVide - listObjetCreneau.length;

  // on recupere la taille moyenne des creneaux
  //const tailleMoyenneCreneaux = tailleCreneauMoyen(listObjetCreneau);

  //console.log("tailleMoyenneCreneaux = "+tailleMoyenCreneaux.toString());

  // AJOUT VIDE AVANT CRENEAUX // 
  ///////////////////////////////
  const nbMinutePremierCreneauApresDixH : number = recupNbMinuteEntreHeures(heureMin, new HeureFiltre(listObjetCreneau[0].creneauH.heureDebut));
  // si le premier creneau a lieu bien apres 10h 
  if(nbMinutePremierCreneauApresDixH > tailleMoyenCreneaux) {
    // on ajoute des creneaux vide pour decaler les creneaux suivant vers le bas
    for(let v = 0; (v < (nbMinutePremierCreneauApresDixH / tailleMoyenCreneaux)-1) && v < nbCreneauVideAajouter; v++){
      returnTableauCreneau.push('');
    }
  }

  // AJOUT VIDE ENTRE LES CRENEAUX // 
  ///////////////////////////////////

  // pour tous les creneaux
  for (let c = 0; c < listObjetCreneau.length; c++) {
    // on ajoute le creneau
    returnTableauCreneau.push(listObjetCreneau[c]);

    // on ajout du vide ensuite si le creneau suivant
    if(c < listObjetCreneau.length - 1){
      const nbMinuteJusquauProchainCreneau : number = recupNbMinuteEntreHeures(new HeureFiltre(listObjetCreneau[c].creneauH.heureFin), new HeureFiltre(listObjetCreneau[c+1].creneauH.heureDebut));
      if(nbMinuteJusquauProchainCreneau > tailleMoyenCreneaux){
        for(let v = 0; (v < (nbMinuteJusquauProchainCreneau / tailleMoyenCreneaux)-1) && returnTableauCreneau.length < nbCreneauMaxAvecVide - 1; v++){
          returnTableauCreneau.push('');
        }
      }
    }
  }

  // AJOUT VIDE AVANT CRENEAUX // 
  ///////////////////////////////
  /*const nbMinuteDernierCreneauAvantSeizeHeure : number = recupNbMinuteEntreHeures(new HeureFiltre(listObjetCreneau[listObjetCreneau.length - 1].creneauH.heureFin), new HeureFiltre(16,0));
  
  console.log("minutes avant 16h = "+nbMinuteDernierCreneauAvantSeizeHeure.toString());
  // si le dernier creneau a lieu bien avant 16h
  if(nbMinuteDernierCreneauAvantSeizeHeure > tailleMoyenneCreneaux) {
    // on ajoute des creneaux vide pour decaler les creneaux predant vers le haut
    for(let v = 0; v < (nbMinuteDernierCreneauAvantSeizeHeure / tailleMoyenneCreneaux)-1; v++){
      returnTableauCreneau.push('');
    }
  }*/
  // on renvoi la liste de creneaux avec du vide 
  return returnTableauCreneau;
}