// store.js
import { reactive } from 'vue';
import { ResaCreneau } from "../fonction/fonctionCreneau";

export const DataCommune = reactive({
    CompteEtablissement : '' as any,
    IdEtablissement : 0 as number,
    SemaineAffichee : '' as any,
    IdSemaineAffichee : 0 as number,
    messageDansPlanning : "" as string,
    IdFamille : 0 as number,
    Resident : '' as any,
    Famille : '' as any,
    semaineVisioActuellementAffiche: [] as any[], 
    listResaSemaineActuellementAffiche:  [] as ResaCreneau[], 

});