import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6024a182"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ensembleAfficheurJour"
}
const _hoisted_2 = {
  key: 1,
  class: "blocCreneaux"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AfficheurCreneau = _resolveComponent("AfficheurCreneau")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.localLabelJour != '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.localLabelJour), 1))
      : _createCommentVNode("", true),
    (_ctx.listCreneauxJournee.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listCreneauxJournee.length, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "creneau",
              key: index
            }, [
              _createVNode(_component_AfficheurCreneau, {
                localCreneau: _ctx.listCreneauxJournee[index-1]
              }, null, 8, ["localCreneau"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}