import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82d73792"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affichageHoraire" }
const _hoisted_2 = { class: "statusCreneau" }
const _hoisted_3 = { class: "affichageHoraire" }
const _hoisted_4 = { class: "statusCreneau" }
const _hoisted_5 = {
  key: 2,
  class: "fondCreneauGrise"
}
const _hoisted_6 = { class: "affichageHoraire" }
const _hoisted_7 = { class: "statusCreneau" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.localHoraire != '' && _ctx.localStatus == 'Disponible')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fondCreneau",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicReserverCreneau && _ctx.clicReserverCreneau(...args)))
        }, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.localHoraire), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.localStatus), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.localHoraire != '' && _ctx.localStatus == 'Mon créneau')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "fondMonCreneau",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clicSupprimerCreneau && _ctx.clicSupprimerCreneau(...args)))
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.localHoraire), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.localStatus), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.localHoraire != '' && _ctx.localStatus != 'Disponible' && _ctx.localStatus != 'Mon créneau' )
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.localHoraire), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.localStatus), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}