import { DateFiltre, datesEgales } from "./fonctionDate";
import { CreneauHoraire, creneauEgaux } from "./fonctionHoraire";

export class ResaCreneau {
    public id_etablissement: number;
    public date: DateFiltre;
    public creneauH: CreneauHoraire;
    public mailUtilisateurWivy: string;
    public emailFamille: string;
    public numTelFamille: string;
  
    // 1. On déclare les signatures "surchargées" (facultatives, mais recommandées pour la clarté) :
    constructor();
    constructor(idEtablissement: number, date: DateFiltre, creneauH: CreneauHoraire, mailUtilisateurWivy :string, emailFamille :string, numTelFamille :string);
    constructor(objet: any);
  
    // 2. On n’a qu’un seul constructeur qui implémente la logique.
    constructor(param1?: number | any, param2?: DateFiltre, param3?: CreneauHoraire, param4?: string, param5?: string, param6?: string) {
      if (typeof param1 === 'object') {
        // Signature "constructor(jour: number, mois: number, annee: number)" ou sans paramètre
        this.id_etablissement = param1.id_etablissement ?? 99;
        this.date = new DateFiltre(param1.date) ?? new DateFiltre();
        this.creneauH = new CreneauHoraire(param1.creneauH) ?? new CreneauHoraire();
        this.mailUtilisateurWivy = param1.mailUtilisateurWivy ?? "";
        this.emailFamille = param1.emailFamille ?? "";
        this.numTelFamille = param1.numTelFamille ?? "";
      } else {
        this.id_etablissement = param1;
        // Signature "constructor(objet: any)"
        this.date = param2 ?? new DateFiltre();
        this.creneauH = param3 ?? new CreneauHoraire();
        this.mailUtilisateurWivy = param4 ?? "";
        this.emailFamille = param5 ?? "";
        this.numTelFamille = param6 ?? "";
      }
    }

    public familleDejaDessus(inFamille : any): boolean {

      return inFamille.email == this.emailFamille || inFamille.telStandard == this.numTelFamille;
    }
  }

  // on verifie si 2 dates sont egales
export function resaEgales(inResa1: ResaCreneau, inResa2: ResaCreneau): boolean {
  return (
    inResa1.id_etablissement == inResa2.id_etablissement &&
    datesEgales(inResa1.date, inResa2.date) &&
    creneauEgaux(inResa1.creneauH, inResa2.creneauH) &&
    inResa1.mailUtilisateurWivy == inResa2.mailUtilisateurWivy &&
    inResa1.emailFamille == inResa2.emailFamille &&
    inResa1.numTelFamille == inResa2.numTelFamille
  );
}
